/* barlow-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url('_fonts/barlow-v12-latin/barlow-v12-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* barlow-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src: url('_fonts/barlow-v12-latin/barlow-v12-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* barlow-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: url('_fonts/barlow-v12-latin/barlow-v12-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* barlow-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 700;
  src: url('_fonts/barlow-v12-latin/barlow-v12-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

$max_w: 1500px;
$sfondo: #f6f8f7;

body {
  font-family: 'Barlow';
  font-size: 16px;
  width: 100%;
  background-color: $sfondo;
  max-width: $max_w;
  margin: auto;
}
a {
  cursor: pointer;
  color: black;
}
a:hover {
  text-decoration: underline;
  color: black;
}

a.decoro:hover {
  color: red !important;
}

label {
  display: inline-block;
  margin-bottom: 0rem;
  margin-top: .5rem;
}

.evidenzia-verde {
  color: green !important;
}
.evidenzia-rosso {
  color: red !important;
}

hr {
  border-top: 1px solid #73bcb3;
}

.table .thead-dark th {
  color: #fff;
  background-color: gray;
  border-color: gray;
}

.card-body {
  border: 0 !important;
}

.modal {
  left: auto;
  max-width: 1500px;
}
.modal-header {
  padding-top: 0.7rem;
  padding-bottom: 0.3rem;
}
.modal-header, .modal-footer {
  border: 0;
}
.modal-header .close {
  color: black;
}
.modal-title {
  font-weight: bold;
}
.modal-backdrop.show {
    opacity: 0.05 !important;
}
.modal-dialog {
  top:35px;
  right:7px;
  width: 100%;
  position: absolute;
}
.modal-xs {
  max-width: 220px;
}
.modal-md {
  max-width: 600px;
}
.modal-xxl {
  max-width: 1485px;
}
.modal-full {
  max-width: 99.1%;
}
.modal-full-fixed {
  position: relative;
}

.modal-server {
  width: 400px;
  height: 220px;
  text-align: center;
}

.nav-item .nav-link {
  border: 0 !important;
}
.nav-link {
  background-color: transparent !important;
}
a.nav-l {
  color: white !important;
}
.nav-link:hover, a.nav-l:hover {
  text-decoration: none;
  color: DarkOliveGreen !important;
}
.nav-area-sponsor {
  background-color: #e4032e;
}
.nav-area-sponsor:hover {
  color: black !important;
}

.navbar-toggler {
  padding: 0 !important;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.nav-pills .nav-link {
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
  color: black !important;
  border: 1px solid #ddd !important;
  background-color: white !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.nav-pills .nav-link.active, .nav-link.hover, .nav-pills .show > .nav-link {
  color: black !important;
  border: 1px solid #ddd !important;
  background-color: #ddd !important;
}
.nav-pills .nav-link:hover {
  border: 1px solid black !important;
}

.dropdown-menu {
  border: 0 !important;
  border-radius: 0 !important;
  color: white !important;
  background-color: #142f61 !important;
  min-width: 12rem !important;
}
.dropdown-v {
  margin-bottom: 4px;
  border-bottom: 1px solid white;
}

.bg-navbar {
  background-color: black;
}
.bg-navbar-regia {
  background-color: black;
}

.btn-primary {
    color: #fff;
    background-color: black;
    border-color: black;
}
.btn-primary:hover {
    color: #fff;
    background-color: DarkOliveGreen;
    border-color: DarkOliveGreen;
}

.btn-timer {
  font-family: monospace;
}

.alert-danger {
    color: white;
    background-color: #f1b7b3;
    border-color: #142f61;
}

.welcome {
  color: black;
}

.img-sponsor {
  max-height: 90px;
  width: auto;
}

#modal-zoom {
  z-index: 2001;
}
/* forse non serve più
#modal-survey-studente,
#modal-survey-detail-studente {
  z-index: 2000;
}
*/
#modal-survey-detail-votanti {
  z-index: 1051; /* 06 09 2024 */
  background-color: rgba(0, 0, 0, 0.5);
}
.zoom-in {
  cursor: zoom-in;
  border-radius: .25rem;
}
.zoom-out {
  cursor: zoom-out;
  border: 1px solid black;
}
.zoom-icon {
  top: 0px;
  left: 20px;
  position: absolute;
}
.close {
  font-size: 1.5rem;
}
.loading {
  width: 100%;
  text-align: center;
  margin-top: 10rem;
}
.loading2 {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}
.login-form {
  max-width:410px; //302px;
}
.nota {
  font-size: 0.8rem;
}
.text-small {
  font-size: 0.9rem;
}
.no-display {
  display: none;
}
.progress {
  border-radius: 0;
  color: white;
  background-color: grey;
  height: 32px;
  font-size: 1.1rem;
}
.progress-bar {
  min-width: 2.0em;
}

.testo-domanda {
  font-size: 1.1rem;
}
.testo-domanda-lg {
  font-size: 1.4rem;
}
.testo-risposta {
  font-size: 1.2rem;
}
.testo-risposta-lg {
  font-size: 1.5rem;
}
.testo-progress {
  font-size: 1.1rem;
}
.testo-progress-lg {
  font-size: 1.5rem;
  font-weight: bold;
}

.etichetta-grigia {
  color: white;
  background-color: #939290;
  padding: 5px;
}

.sponsor {
  text-align: center;
}

.faculty {
  margin-bottom: 20px;
  .foto {
    border-radius: 5rem;
    max-width: 90%;
  }
}

a.orario, .orario {
  color: black;
}

.attivita p {
  margin-bottom: 0rem;
}

.programma-attivo {
  background-color: black;
}

.nav-programma {
  color: grey !important;
}
.nav-programma:hover {
  color: black !important;
}
.nav-programma.active {
  color: black;
}

.navbar-bg {
  background-color: #505050;
}

.btn-100 {
  width: 100%;
}

.btn-email {
  width: 302px;
}
.btn-risposte {
  text-align: left;
  padding: 0 !important;
  padding-right: 0.5em !important;
  white-space: normal !important;
  background-color: #eeeeee;
}
.btn-risposte:hover {
  background-color: #b9d9d6;
}
.sel-risposte {
  background-color: #b9d9d6 !important;
}

.btn-risposte-l {
  text-align: center;
  padding-left: 0.5rem !important;
  white-space: normal !important;
  color: #73bcb3;
  background-color: white;
  border: 1px solid #73bcb3;
}

.txt-risposta {
  font-size: 1.2rem;
  padding-left: 0.5em;
}
.td-risposta {
  min-width: 40px;
  text-align: center;
}
.td-risposta-A {
  color: #fff;
  font-size: 2em;
  padding: 0.20em;
  background-color: #f15728;
}
.td-risposta-B {
  color: #fff;
  font-size: 2em;
  padding: 0.20em;
  background-color: #2987d9;
}
.td-risposta-C {
  color: #fff;
  font-size: 2em;
  padding: 0.20em;
  background-color: #f2a81d;
}
.td-risposta-D {
  color: #fff;
  font-size: 2em;
  padding: 0.20em;
  background-color: #d84293;
}
.td-risposta-E {
  color: #fff;
  font-size: 2em;
  padding: 0.20em;
  background-color: #005736;
}
.td-risposta-F {
  color: #fff;
  font-size: 2em;
  padding: 0.20em;
  background-color: #59beb3;
}
.td-risposta-G {
  color: #fff;
  font-size: 2em;
  padding: 0.20em;
  background-color: #432a59;
}
.td-risposta-H {
  color: #fff;
  font-size: 2em;
  padding: 0.20em;
  background-color: #a8ce67;
}
.td-risposta-I {
  color: #fff;
  font-size: 2em;
  padding: 0.20em;
  background-color: #008c7f;
}
.td-risposta-L {
  color: #fff;
  font-size: 2em;
  padding: 0.20em;
  background-color: #999999;
}

/*
.num-risposta {
  font-size: 1.2rem;
}
.num-risposta-A::first-letter {
  color: #fff;
  font-size: 3em;
  float: left;
  padding: 0.20em;
  line-height: 1.0em;
  margin-right: 0.20em;
  background-color: #f15728;
}
.num-risposta-B::first-letter {
  color: #fff;
  font-size: 3em;
  float: left;
  padding: 0.20em;
  line-height: 1.0em;
  margin-right: 0.20em;
  background-color: #2987d9;
}
.num-risposta-C::first-letter {
  color: #fff;
  font-size: 3em;
  float: left;
  padding: 0.20em;
  line-height: 1.0em;
  margin-right: 0.20em;
  background-color: #f2a81d;
}
.num-risposta-D::first-letter{
  color: #fff;
  font-size: 3em;
  float: left;
  padding: 0.20em;
  line-height: 1.0em;
  margin-right: 0.20em;
  background-color: #d84293;
}
.num-risposta-E::first-letter {
  color: #fff;
  font-size: 3em;
  float: left;
  padding: 0.20em;
  line-height: 1.0em;
  margin-right: 0.20em;
  background-color: #005736;
}
.num-risposta-F::first-letter {
  color: #fff;
  font-size: 3em;
  float: left;
  padding: 0.20em;
  line-height: 1.0em;
  margin-right: 0.20em;
  background-color: #59beb3;
}
.num-risposta-G::first-letter {
  color: #fff;
  font-size: 3em;
  float: left;
  padding: 0.20em;
  line-height: 1.0em;
  margin-right: 0.20em;
  background-color: #432a59;
}
.num-risposta-H::first-letter {
  color: #fff;
  font-size: 3em;
  float: left;
  padding: 0.20em;
  line-height: 1.0em;
  margin-right: 0.20em;
  background-color: #a8ce67;
}
.num-risposta-I::first-letter {
  color: #fff;
  font-size: 3em;
  float: left;
  padding: 0.20em;
  line-height: 1.0em;
  margin-right: 0.20em;
  background-color: #008c7f;
}
.num-risposta-L::first-letter {
  color: #fff;
  font-size: 3em;
  float: left;
  padding: 0.20em;
  line-height: 1.0em;
  margin-right: 0.20em;
  background-color: #999999;
}
*/
.btn-risposta {
  color: white;
  font-size: 1.1em;
}

.btn-risposta-A {
  background-color: #f15728;
  border: 1px solid #f15728;
}
.btn-risposta-B {
  background-color: #2987d9;
  border: 1px solid #2987d9;
}
.btn-risposta-C {
  background-color: #f2a81d;
  border: 1px solid #f2a81d;
}
.btn-risposta-D {
  background-color: #d84293;
  border: 1px solid #d84293;
}
.btn-risposta-E {
  background-color: #005736;
  border: 1px solid #005736;
}
.btn-risposta-F {
  background-color: #59beb3;
  border: 1px solid #59beb3;
}
.btn-risposta-G {
  background-color: #432a59;
  border: 1px solid #432a59;
}
.btn-risposta-H {
  background-color: #a8ce67;
  border: 1px solid #a8ce67;
}
.btn-risposta-I {
  background-color: #008c7f;
  border: 1px solid #008c7f;
}
.btn-risposta-L {
  background-color: #999999;
  border: 1px solid #999999;
}

.div-risposta-A {
  border: 1px solid #f15728;
}
.div-risposta-B {
  border: 1px solid #2987d9;
}
.div-risposta-C {
  border: 1px solid #f2a81d;
}
.div-risposta-D {
  border: 1px solid #d84293;
}
.div-risposta-E {
  border: 1px solid #005736;
}
.div-risposta-F {
  border: 1px solid #59beb3;
}
.div-risposta-G {
  border: 1px solid #432a59;
}
.div-risposta-H {
  border: 1px solid #a8ce67;
}
.div-risposta-I {
  border: 1px solid #008c7f;
}
.div-risposta-L {
  border: 1px solid #999999;
}

.img-logo {
  width: 110px;
}
.santino {
  width: 100%;
  border-radius: 20px;
  margin-top: 6px;
}

.container {
  max-width: 100%;
}

.colore-primario {
  color: black;
}

.form-control, .custom-select {
  background-color: $sfondo;
  border-color: $sfondo;
}

.form-login {
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
}

.recaptcha, .g-recaptcha {
	margin-bottom: 15px;
}

.scroll-wrapper {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.risposta {
  background-color: $sfondo;
}

.my-toast {
  top: -12px;
  right: -12px;
  margin: 20px;
  position: absolute;
  z-index: 9999;
  min-width: 150px;
  text-align: center;
  border: 1px solid #808080;
  background-color: white !important;
}

.my-toast-2 {
  top: 65px;
  right: 10px;
  padding: 10px;
  position: absolute;
  z-index: 9999;
  text-align: center;
  border: 1px solid #dddddd;
  background-color: white !important;
}

.toast-survey {
  top: 0px;
  left: 0px;
  margin: 10px;
  padding: 10px;
  position: absolute;
  max-width: 100%;
  z-index: 2000;
  border: 1px solid black;
  background-color: white !important;
}

.toast-timer {
  font-family: monospace;
  font-size: 1.5em;
  top: -7px;
  left: 2px;
  margin: 15px;
  position: fixed;
  z-index: 9999;
  min-width: 150px;
  text-align: center;
  border: 1px solid #808080;
  color: black !important;
  background-color: greenyellow !important;
}
.timer-sforato {
  color: white !important;
  background-color: red !important;
}

.new {
  font-size: 0.8rem;
  color:#e4032e;
}

.desc_file_link {
    line-height: 32px;
}

.slide {
  width: 100%;
}
.nav-slide {
  position: fixed;
  bottom: 0px;
  width: 102%;
  max-width: $max_w;
  margin-left: 1px;
  background-color: white;
}
.btn-slide {
  color: white;
  line-height: 1.2em;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.slide-counter {
  font-size: 1.5em;
}
.slide-attivita {
  font-size: 1.1rem;
  font-weight: bold;
}
.slide-attore {
  font-size: 0.9rem;
  font-style: italic;
}

.preferiti {
  background-color: greenyellow !important;
}

.risposta-da {
  font-weight: bold;
  text-transform: uppercase;
}

.table-domande {
  border: 1px solid #808080;
  td { padding-bottom: 0.8rem; }
}
.testo-table-domande {
  font-size: 1.2rem;
}

.cel-addon {
  background-color: #f6f8f7;
  border: 0;
}

.pulse {
   /* Chrome, Safari, Opera */
  -webkit-animation: PULSE 1s infinite;
  /* Internet Explorer */
  -ms-animation: PULSE 1s infinite;
  /* Standard Syntax */
  animation: PULSE 1s infinite;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes PULSE{
   0%{color:black;}
	100%{color: white;}
}
/* Internet Explorer */
@-ms-keyframes PULSE{
  0%{color:black;}
	100%{color: white;}
}
/* Standard Syntax */
@keyframes PULSE{
  0%{color:black;}
	100%{color: white;}
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: grey !important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: grey !important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: grey !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: grey !important;
}

@media only screen and (max-width: 1024px)
{
  body {
    width: 100%;
  }
}

@media only screen and (max-width: 768px)
{
  .modal-dialog {
    top: 45px;
    right: 0px;
    width: auto;
  }
  .affiliazione {
    font-size: 0.8rem;
  }
  .nav-item {
    border-bottom: 1px solid white;
  }
  .nav-tabs {
    border: 1px solid #dee2e6 !important;
  }
  .nav-tabs .nav-link {
    border: 0 !important;
  }
  .dropdown-v {
    border-bottom: 0;
  }
  .welcome {
    font-size: 1.0em;
  }
}
